import ICar from '../interfaces/ICar';

const Mercedes1 : ICar = {
  id: '1',
  marque: 'Mercedes Class V',  
  image: 'images/cars/mercedes_classe_v.png',
  imageAlt: 'Sièges enfants',
  nbPlaces: 7,
  equipement: [
    // {
    //   nom: 'Equipement 1',
    //   definition: 'Dans la Mercedes Class V vous y retrouverez tout le comfort propre à la marque allemande.',
    //   photo: '/images/cars/mercedes_classe_v.png',
    //   alt: 'equip 1',
    // },
    // {
    //   nom: 'Equipement 2',
    //   definition: 'Ceci est mon super équipement 2',
    //   photo: '/images/cars/mercedes_classe_v.png',
    //   alt: 'equip 2',
    // },
    // {
    //   nom: 'Equipement 3',
    //   definition: 'Ceci est mon super équipement 3',
    //   photo: '/images/cars/mercedes_classe_v.png',
    //   alt: 'equip 3',
    // },
  ],
};
const Opel1 : ICar = {
  id: '2',
  marque: 'Opel Vivaro',  
  // image: 'images/cars/opel_vivaro.jpg',
  image: 'images/koffi-transports-monceau-sur-sambre.jpg',
  imageAlt: 'Sièges enfants',
  nbPlaces: 8,
  equipement: [
    // {
    //   nom: 'Equipement 1',
    //   definition: 'Dans la Mercedes Class V vous y retrouverez tout le comfort propre à la marque allemande.',
    //   photo: '/images/cars/opel_vivaro.jpg',
    //   alt: 'equip 1',
    // },
    // {
    //   nom: 'Equipement 2',
    //   definition: 'Ceci est mon super équipement 2',
    //   photo: '/images/cars/opel_vivaro.jpg',
    //   alt: 'equip 2',
    // },
  ],
};
const Ford1 : ICar = {
  id: '3',
  marque: 'Ford Custom Tourneo',  
  image: 'images/cars/ford_custom_tourneo_1.jpg',
  // image: 'images/cars/ford_custom_tourneo.png',
  imageAlt: 'Sièges enfants',
  nbPlaces: 8,
  equipement: [
    {
      nom: 'Sièges',
      definition: "6 places sont disponibles à l'arrière et 2 sièges enfants sont mis à votre disposition.",
      photo: '/images/cars/Tourneo/Sièges.jpg',
      alt: 'equip 1',
    },
    {
      nom: 'Espace',
      definition: "Un grand coffre est disponible afin d'accueillir tous vos bagages",
      photo: '/images/cars/Tourneo/Coffre.jpg',
      alt: 'equip 2',
    },
    {
      nom: 'Comfort',
      definition: 'Vous serez assis comfortablement durant tout le trajet',
      photo: '/images/cars/Tourneo/Comfort.jpg',
      alt: 'equip 3',
    },
    // {
    //   nom: 'Equipement 1',
    //   definition: 'Le Ford Custom Tourneo est un grand véhicule silencieux et agréable. Parfait pour vous reposer durant un long voyage.',
    //   photo: '/images/cars/ford_custom_tourneo.png',
    //   alt: 'equip 1',
    // },
  ],
};
// const BMW1 : ICar = {
//   id: '4',
//   marque: 'BMW Series 5 Black',  
//   image: 'images/cars/bmw_series5_black.jpg',
//   imageAlt: 'Sièges enfants',
//   nbPlaces: 3,
//   equipement: [  ],
// };
const Cars = [
  Mercedes1,
  Opel1,
  Ford1,
  // BMW1,
];

export default Cars;
