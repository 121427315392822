import React from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.scss';
import { LastUpdated } from '../constants/Numbers';

const Footer = () => (
  <footer>
    <div className="footer-blocks content-width">
      <div className="footer-block" id="know-me">
        <h2>ME CONNAÎTRE</h2>
        <p>La société Koffi Transports est un service de navettes situé à Charleroi.<br />
          Je vous propose mes services partout en Belgique, dans le nord de la France, en Allemagne (Düsseldorf), au Luxembourg et au Pays-Bas (Amsterdam).</p>
      </div>
      <div className="footer-block" id="contact-me">
        <h2>CONTACTEZ-MOI</h2>
        <p className="footer-phone"><span className="icon phone-out" /> 0473/19.94.70</p>
        {/* <p className="footer-surtaxe">(Appel local non surtaxé)</p> */}
        <Link to="contact">
          <img src="images/rappel.png" alt="Bouton on vous rappelle gratuitement" />
        </Link>
        <p>Rue du gros buisson, 23 <br />
          6031 Monceau-sur-Sambre <br />
          <span className="icon mail-open" /><span className="footer-mail"> <a href="mailto:info@koffi.be">info@koffi.be</a></span><br />
          N° TVA : 0664.516.613</p>
      </div>
      <div className="footer-block" id="menu">
        <h2>MENU</h2>
        <ul>
          <li><Link to="">ACCUEIL</Link></li>
          <li><Link to="chauffeur-prive">SERVICES</Link></li>
          <li><Link to="cars">VÈHICULES</Link></li>
          <li><Link to="contact">CONTACT</Link></li>
        </ul>
      </div>
    </div>
    <div className="container footer-container content-width">
      <p id="footer-container-left">
        <span>KoffiTransport by </span>
        <a target="_blank noreferrer" href="https://usidistrib.com">
          Usidistrib
        </a>
        <span> © 2025</span>
      </p>
      <p id="footer-container-right">
        Dernière mise à jour : {LastUpdated}
      </p>
    </div>
  </footer>
);

export default Footer;
