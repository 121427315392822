import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Links from '../constants/Links';
import Hamburger from '../constants/Hamburger';
import '../css/header.scss';

const Header = () => {
  const [highlighted, highlight] = useState(window.location.pathname.substring(1));
  const [show, setShow] = useState(false);

  const ShowHideMenu = (test: string, show: boolean) => {
    highlight(test);
    setShow(show);
  };

  return (
    <nav>
      <div className="navbar">
        <div className="navbar-header">
          <div className="logo">
            <Link onClick={() => ShowHideMenu('', false)} className="home" to="/">
              {/* <img height="30" src="/logo192.png" />
            UsiCovid */}
              <img src="/images/Logo-navette-privee-koffi-300x73.jpg" alt="Logo Koffi Transport" />
            </Link>
            <span className="logo_info">
            {/* <span className="logo_surtaxe">(appel local non surtaxé)</span> */}
              <span className="icon mail" /> <span className="color-white"><a href="mailto:info@koffi.be">info@koffi.be</a></span> | <span className="icon phone" /> <span className="color-white">0473 19 94 70</span>
            </span>
          </div>
        </div>
        <Hamburger onMenuClick={() => { ShowHideMenu(highlighted, !show); }} />
        <div id="navbar-menu" className={`navbar-collapse ${show ? '' : ' hidden'}`}>
          <ul className="nav navbar">
            {Links.map((link) => {
              return (
                <li key={link.name}>
                  <Link onClick={() => ShowHideMenu(link.url, false)} to={`/${link.url}`} className={highlighted === link.url ? 'selected' : ''}>
                    {link.name.toUpperCase()}
                  </Link>
                </li>)
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
