// import React from "react";
//import { SEND_MAIL_ERROR, SEND_MAIL_LOADING, SEND_MAIL_SUCCESS } from "../../../constants/actionTypes";
import axiosInstance from "../../../helpers/Axios";

const getDate = (dob: string) => {
  if (dob === undefined)
    return undefined;
  const tokens: string[] = dob.split('/');
  const year: string = tokens[2];
  const month: string = tokens[1].length < 2 ? `0${tokens[1]}` : tokens[1];
  const day: string = tokens[0].length < 2 ? `0${tokens[0]}` : tokens[0];
  const newDob: string = `${year}-${month}-${day}`;
  return newDob;
};

export const register = (form: any) =>
//(dispatch: any) => 
{
//    dispatch({ type: SEND_MAIL_LOADING, });

    const newOrder = {
//        ProfilePicture:"/images/avatar-icon-images-4.jpg",
        OrderId:1,
        FirstName:form?.firstName,
        LastName:form?.lastName,
        Email:form?.emailAddress,
        PhoneNumber:form?.phoneNumber,
        NbAdults:form?.nbAdults ? parseInt(form?.nbAdults) : undefined,
        NbChildren:form?.nbChildren ? parseInt(form?.nbChildren) : undefined,
        NbBabies:form?.nbBabies ? parseInt(form?.nbBabies) : undefined,
        NbChildSeats:form?.nbChildseats ? parseInt(form?.nbChildseats) : undefined,
        NbGrandeValises:form?.nbGrandeValises ? parseInt(form?.nbGrandeValises) : undefined,
        NbValisesCabine:form?.nbValisesCabine ? parseInt(form?.nbValisesCabine) : undefined,
        NbGolfBags:form?.nbGolfBags ? parseInt(form?.nbGolfBags) : undefined,
        From:form?.fromAddress,
        StartDate:form?.startDate,
        FlightDate:form?.flightDate,
        BackDate:form?.backDate,
        StartFlight:form?.startFlight,
        BackFlight:form?.backFlight,
        BackTime:form?.backTime,
        FromFlight:form?.fromFlight,
        To:form?.toAddress,
        Comment:form?.comment,
        IdModification:3,
        DateModification:getDate(form?.dob),
        IdCreation:4,
        DateCreation:getDate(form?.dob)
    };

    axiosInstance.post('/client/requestTrack', newOrder)      
    .then(response => {
//      const idStr = response.data?.split('-')[0];
      // console.log('idstr',idStr);
//      const id = idStr ? parseInt(idStr) : undefined;
      // console.log('id',id);
      // console.log('/auth/register -> response statuts', response.status)
      /*
      if (response.status === 201) {
          dispatch({
            type: SEND_MAIL_SUCCESS,
            payload: response.data,
            id: id,
          });
        }
        else {
          dispatch({
            type: SEND_MAIL_ERROR,
            payload: 'unable to register this user',
          });
        }
        */
      }
      )
      .catch((err) => {
        // const error = err.response === undefined ? 
        // 'Server in not reachable'
        // : err.response.data;
//        const detailedError = error?.detail;
        /*
        dispatch({
          type: SEND_MAIL_ERROR,
          payload: detailedError !== undefined ? detailedError : error,
        })
*/
      }
      );
};
