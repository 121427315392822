import React, { useState } from 'react'; //, useContext
import { Label, Form, Input, Button } from 'reactstrap';
//import { SEND_MAIL_ERROR, SEND_MAIL_LOADING, SEND_MAIL_SUCCESS } from "../constants/actionTypes";
//import axiosInstance from "../helpers/Axios";
import { register as registerUser } from '../context/actions/auth/register';
//import { GlobalContext } from '../context/Provider';
import '../css/contactform.scss';

interface IContactForm {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    fromAddress: string;
    toAddress: string;
    nbAdults: number;
    nbChildren: number;
    nbBabies: number;
    nbChildSeats: number;
    comment: string;
}

const defaultContactForm = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    fromAddress: "",
    toAddress: "",
    nbAdults: 0,
    nbChildren: 0,
    nbBabies: 0,
    nbChildSeats: 0,
    comment: "",
}

//const context = useContext(GlobalContext);
//const registerDispatch = context?.registerDispatch;


const ContactForm = () => {
    const [form, setForm] = useState<IContactForm>(defaultContactForm);
    // const [error, setError] = useState(undefined);


    const onSubmit = (e: React.FormEvent, form: any) => {
        e.preventDefault();
        // if (form.emailAddress && !hasEmailError && !hasPasswordError) {
        //   registerUser(form)(registerDispatch);
        // }
        console.log("form", form);
        registerUser(form);
        //alert("Le site est actuellement en maintenance. Veuillez appeler le 0473/19 94 70");
        alert("Votre demande de réservation a été envoyée avec succès. Vous recevrez bientôt un e-mail avec le bon de commande.");
    };

    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    const hasEmailError = !pattern.test(form.emailAddress);
    const emailError = (): string => {
        if (!form.emailAddress)
            return "";
        if (!pattern.test(form.emailAddress))
            return "The email is not valid";
        return "a";
    };
    //   const hasPasswordError = form.password !== form.ConfirmPswd;

    const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
        // setError(undefined);
        setForm({ ...form, [e.currentTarget.id]: e.currentTarget.value });
    };

    return (
        <Form className="contact-form content-width" method="post" onSubmit={(e) => onSubmit(e, form)} >
            <h2>Envoyez-nous un message…</h2>
            <div className="contact-form-group">
                <h3>Informations personnelles</h3>
                <div className="contact-form-name contact-form-item">
                    <Label>Nom et prénom<span className="mandatory">*</span></Label>
                    <div className="flex">
                        <div className="contact-form-name__first-name">
                            <Input type="text" id="firstName" name="firstName" autoComplete="first-name" onChange={onChange} placeholder="Prénom" />
                            {/* <p>Prénom</p> */}
                        </div>
                        <div className="contact-form-name__last-name">
                            <Input type="text" id="lastName" name="lastName" autoComplete="family-name" onChange={onChange} placeholder="Nom" />
                            {/* <p>Nom</p> */}
                        </div>
                    </div>
                </div>
                <div className="contact-form-email contact-form-item">
                    <Label>Adresse e-mail<span className="mandatory">*</span></Label>
                    <Input type="text" id="emailAddress" title="Email address" name="emailAddress" autoComplete="email" onChange={onChange} />
                    {/* placeholder="Email-address" */}
                    {hasEmailError ?
                        <Label id="emailError" name="emailError" className="errors">
                            {emailError()}
                        </Label>
                        : <span />
                    }
                </div>
                <div className="contact-form-phone contact-form-item">
                    <Label>Numéro de téléphone<span className="mandatory">*</span></Label>
                    <Input type="text" id="phoneNumber" name="phoneNumber" autoComplete="phone" onChange={onChange} />
                </div>
            </div>
            <div className="contact-form-group">
                <h3>Informations pour la prise en charge</h3>
                <div className="flex contact-form-item flex-wrap numbers">
                    <div className="contact-form-nb-adults">
                        <Label>Nombre d'adultes</Label>
                        <Input type="text" id="nbAdults" name="nbAdults" onChange={onChange} />
                    </div>
                    <div className="contact-form-nb-children">
                        <Label>Nombre d'enfants</Label>
                        <Input type="text" id="nbChildren" name="nbChildren" onChange={onChange} />
                    </div>
                </div>
                <div className="flex contact-form-item flex-wrap numbers">
                    <div className="contact-form-nb-babies">
                        <Label>Nombre de sièges bébés (maxi cosi)</Label>
                        <Input type="text" id="nbBabies" name="nbBabies" onChange={onChange} />
                    </div>
                    <div className="contact-form-nb-childseats">
                        <Label>Nombre de sièges enfants (réhausseurs)</Label>
                        <Input type="text" id="nbChildseats" name="nbChildseats" onChange={onChange} />
                    </div>
                    <img className="contact-form-siege-enfant" src="/images/cars/Siège enfant.png" alt="Siège enfant" />
                </div>
                <p className="info">
                    2 sièges enfants (réhausseurs) sont mis à votre disposition :
                </p>
                <div className="flex space-between contact-form-item flex-wrap numbers">
                    <div className="contact-form-nb-grandes-valises">
                        <Label>Nombre de grandes valises</Label>
                        <Input type="text" id="nbGrandeValises" name="nbGrandeValises" onChange={onChange} />
                    </div>
                    <div className="contact-form-nb-valises-cabine">
                        <Label>Nombre de valises de cabine</Label>
                        <Input type="text" id="nbValisesCabine" name="nbValisesCabine" onChange={onChange} />
                    </div>
                    <div className="contact-form-nb-golfbags">
                        <Label>Nombre de sacs de golf</Label>
                        <Input type="text" id="nbGolfBags" name="nbGolfBags" onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="contact-form-group">
                <h3>Informations pour le trajet aller</h3>
                <div className="flex space-between addresses contact-form-item">
                    <div className="contact-form-from">
                        <Label>Adresse de départ<span className="mandatory">*</span></Label>
                        <Input type="text" id="fromAddress" name="fromAddress" autoComplete="address" onChange={onChange} />
                    </div>
                    <div className="contact-form-to">
                        <Label>Adresse de destination<span className="mandatory">*</span></Label>
                        <Input type="text" id="toAddress" name="toAddress" autoComplete="destination" onChange={onChange} />
                    </div>
                </div>
                <div className="contact-form-startdate">
                    <Label>Jour et heure de prise en charge à domicile</Label>
                    <Input type="text" id="startDate" name="startDate" onChange={onChange} />
                </div>
            </div>
            <div className="contact-form-group">
                <div className="flex space-between flight contact-form-item">
                    <div className="min400">
                        <h3>Informations pour le vol aller</h3>
                        <div className=" ">
                            <div className="contact-form-flightdate">
                                <Label>Jour et heure du vol aller</Label>
                                <Input type="text" id="flightDate" name="flightDate" onChange={onChange} />
                            </div>
                            <div className="contact-form-startflight">
                                <Label>Numéro de vol départ</Label>
                                <Input type="text" id="startFlight" name="startFlight" onChange={onChange} />
                            </div>
                        </div>
                    </div>
                    <div className="min400">
                        <h3>Informations pour le vol retour</h3>
                        <div className="flex space-between">
                            <div className="contact-form-backdate">
                                <Label>Jour et heure du vol retour</Label>
                                <Input type="text" id="backDate" name="backDate" onChange={onChange} />
                            </div>
                            <div className="contact-form-backflight">
                                <Label>Numéro de vol retour</Label>
                                <Input type="text" id="backFlight" name="backFlight" onChange={onChange} />
                            </div>
                        </div>
                        <div className="flex space-between">
                            <div className="contact-form-backtime">
                                <Label>Heure d'arrivée du vol retour</Label>
                                <Input type="text" id="backTime" name="backTime" onChange={onChange} />
                            </div>
                            <div className="contact-form-fromflight">
                                <Label>Provenance du vol</Label>
                                <Input type="text" id="fromFlight" name="fromFlight" onChange={onChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-form-group contact-form-comment contact-form-item">
                <h3>Informations supplémentaires</h3>
                <Input type="textarea" id="comment" name="comment" onChange={onChange} />
            </div>
            <div className="contact-form-submit">
                <Button
                    className='button pointer'
                    //   disabled={loading}
                    type="submit"
                //   loading={loading.toString()}
                >
                    {/* <i className="fa fa-spinner fa-spin"></i> */}
                    <span className="text">Soumettre</span>
                </Button>
                {/* <Input type="button" value="Soumettre" /> */}
            </div>
        </Form>
    )
}

export default ContactForm;
